.login-bg {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: #777; 
}

.login-logo {
  background-color: #1C1920;
  width: 100.5%;
  border-radius: 25px 25px 0px 0px;
  text-align: center;
}

.login-img {
  margin-top: 3%;
}

.bg-color {
  background-color: #1C1920;
}

.login-welcome {
  text-align: center;
  margin-top: 10%;
}

.login-welcome-text {
  color: white;
  font-size: 17pt;
}

.login-form {
  margin-top: 10%;
}

.login-input {
  border-radius: 19px;
  background-color: #666666 !important;
  border: 2px solid #333 !important;
  color: "#333";
  --text-color: #333;  
}

.login-input:focus {
  border-radius: 19px;
  background-color: white !important;
  border: 2px solid white !important;
  color: #333 !important;
  --text-color: #333 !important;  
}

.login-button {
  border-radius: 20px;
  background-color: #FED102;  
  border: 2px solid #1C1920 !important;
  margin-top: 10% !important;
}

.home-card {
  display: inline-block;
  margin-left: 3%;
}

.cardbody-color {
  background: linear-gradient(90deg, rgba(20,117,239,1) 0%, rgba(46,199,237,1) 100%);
  color: white;
}

.header-search {
  border-radius: 25px;
  background-color: #383A46;
  color: #ffffff !important;
  border: none;
}

.search-header-box {
  height: 20px;
  margin-bottom: 50px;
}

.searchSideBarShow {
  display:block;
}

.searchSideBarHidden {
  display:none;
}

@media screen and (max-width: 640px) {
  .steps .p-steps {
      height: auto;
  }
  .steps .p-steps > ul {
      flex-direction: column;
      height: 100%;
  }
  .steps .p-steps > ul .p-steps-item {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: center;
  }
  .steps .p-steps > ul .p-steps-item:before {
      position: static;
      left: auto;
      top: auto;
      margin-top: 0;
      border-left: 1px solid var(--surface-d);
      border-top: 0 none;
      width: auto;
      height: 100%;
      margin-left: 1rem;
  }
  .steps .p-steps > ul .p-steps-item .p-menuitem-link {
      flex-direction: row;
      overflow: visible;
  }
  .steps .p-steps > ul .p-steps-item .p-menuitem-link .p-steps-title {
      margin: 0 .5rem 0;
  }
  .steps .p-steps > ul .p-steps-item:last-child {
      flex-grow: 0;
  }
  .steps .p-steps > ul .p-steps-item:last-child .p-menuitem-link {
      padding: 0;
  }
  .steps .p-steps > ul .p-steps-item:last-child:before {
      display: none;
  }
}
