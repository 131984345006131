@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

.h-75 {
  height: 75vh;
}

.invisible {
  display: none;
}

table.scroll {
  width: 100%;
  margin-top: 1%;
}

table.scroll input {
  border: none;
  border-bottom: solid thin;
  border-radius: 0px;
  border-color: rgba(29, 140, 248, 0.3);
}

table.scroll tbody,
table.scroll thead {
  display: block;
}

table.scroll tbody {
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

table.scroll tbody > tr > td {
  border: none;
}

tbody td,
thead th {
 /* width: 20%;*/
  padding: 3px;
  /* Optional */
}

.input-group {
  margin-bottom: 0px;
}

.form-group .textarea {
  min-height: 210px;
}

#tablaDeTitulos {
  overflow: auto;
  padding-bottom: 10px;
  max-height: 710px;
}

.cardScroll {
  height: 273px;
  overflow: auto;
  padding-bottom: 10px;
}

#textAreaDescripcion {
  height: 280px;
  overflow: auto;
}

#glosarioTextArea {
  min-height: 0px;
  height: 80px;
  overflow: auto;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.2rem 0.2rem;
  border: none;
}

.modal-content {
  background: transparent;
  display: inline;
}

.modal-lg {
  max-width: 50%;
  min-width: 800px;
  height: 600px;
  max-height: 600px;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-open .modal {
  background-color: rgba(0, 0, 0, 0.6);
}

.ReactTable .rt-thead .rt-resizable-header-content {
  text-align: center;
}

.ReactTable .-pagination .-btn {
  width: 33%;
}

.ReactTable .-pagination .-previous {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right;
}

.ReactTable .-pagination .-next {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: left;
}

.ReactTable .-pagination .-center {
  flex: 1;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  padding: 0px;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background-color: rgb(29, 138, 248, 0.2);
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px; 
  background-color: #F5F5F5; }
  
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #4285F4; 
}
  /*
  .scrollbar-primary {
  scrollbar-color: #4285F4 #F5F5F5;
  }
 */
/* width */
/*
::-webkit-scrollbar {
  width: 10px;
}
*/
/* Track */
/*
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
*/
/* Handle */
/*

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: #002c6f;
}
*/

/* Handle on hover */
.tab-space {
  padding: 0;
}

.nav-pills:not(.nav-pills-icons):not(.nav-pills-just-icons)
  .nav-item
  .nav-link {
  border-radius: 7.5px;
}

.btn-orange.btn-link {
  color: #e6b325;
}

.ReactTable .-pagination .-btn {
  width: auto;
}

.ddlOptions-noBorder {
  border: none;
  border-bottom: solid thin;
  border-radius: 0px;
  border-color: rgba(29, 140, 248, 0.3);
}

.ddlOptions {
  background-color: #525f7f;
  border: none;
  border-bottom: solid thin;
}

#options {
  background-color: #525f7f;
}

.selectedTableRow {
  border: solid 1px #1d8cf8;
  background-color: rgb(29, 140, 248, 0.15);
}

.main-panel > .navbar {
  /* border-top: solid 2px #1D8AF8; */
  background-color: #1e1e2d;
}

.row {
  display: flex;
  align-items: center;
}

.scrollModal {
  max-height: 435px;
  overflow-y: auto;
  overflow-x: hidden;
}

.round {
  border-radius: 10px;
}

.loginCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 45%;
  height: 55%;
  border-radius: 20px;
  background-color: white;
}

.loginLogo {
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
  padding: 10px;
  position: absolute;
}

.formLogin {
  width: 50%;
  margin-left: 20%;
  margin-top: 12%;
}

.content {
  margin: 10px;
  padding: 10px;
  background-color: transparent;
  /* height: 93vh; */
}


.card { 
  height: 100% !important; 
  margin: 0;
}

.card-header {
  padding: 5px 10px;
}
 
.card-title { 
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
}  

.card-body{
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.6rem;
}

.boxshadow{
  box-shadow: 10px 5px 5px #D9D9DB;
}

.backg{
  background: #D9DDE8;
}
 
.card-header:not([data-background-color]) {
  background: #9DA9C3; 
  padding-left: 10px;
  padding-top: 3px;
  color: #fff; 
}

.panel-radius .card-header{
  border-radius: 25px 25px 0px 0px;
} 

.radiuscard{
  border-radius: 25px;
}

.radiuscard10{
  border-radius: 10px;
}

.radiuscard .card-header{
  border-radius: 25px 25px 0px 0px;
} 

.card-radius { 
  height: 100% !important; 
  margin: 0; 
}

.card-radius.card { 
position: relative;
display: flex;
flex-direction: column;
min-width: 0;
word-wrap: break-word;
background-clip: border-box;
border: 0 !important;
}

.card-radius-header {
  padding: 5px 10px;
}
 
.card-radius-title { 
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
}  

.card-radius-body{
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.6rem;
}

.card-login {
  border-radius: 20px;
  background-color: #fff;
}

.text-white {
  color: #ffffff;
}

.input-group-merge {
  box-shadow: none;
}

.kar-component {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 30px;
}

.kar-icon-color {
  color: #FF6060;
}

.dx-field-item-label-text,
.kar-label {
  color: #19314b;
  font-weight: bold;
  font-size: 15px;
  margin: 0px;
}

.kar-btn-login {
  color: #1C1920;
  background-color: #FF6060;
  font-weight: bold;
  font-size: 15px;
  margin: 0px;
}

.kar-btn-login:hover {
  color: #1C1920;
  background-color: #FED102;
  font-weight: bold;
  font-size: 15px;
  margin: 0px;
}

.dx-field-item-label-location-top {
  margin: 0px;
}

.kar-label-black {
  color: #000000;
  font-weight: bold;
  font-size: 15px;
}

 

.dx-datagrid {
  border-radius: 10px;
}

.dx-datagrid-headers,
.dx-datagrid-header-panel {
  border-radius: 10px 10px 0px 0px; 
}

.dx-datagrid .dx-row > td {
  padding-top: 4px;
  padding-bottom: 4px;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  color: #0556b7;
  font-weight: bold;
  font-size: 15px;
}

body {
 background-color: #f5f5f5 !important;  
}

.footer{
  background-color: #f7f7f8 !important;
  bottom: 0;
  position: absolute;
  width: 90%;
  text-align: right;
}

.bg-header { 
  padding: 5px;
  background: #232429 !important;
  border-bottom: 1px solid #e5e5e5 !important;
  position: sticky;
  top: 0;
  z-index: 1020;
}

.bg-header2 { 
  padding: 5px;
  position: sticky;
}

.userName {
  color: #fff;
  font-size: 14px!important;
}
.nomEmpresa {
  color: #777;
  font-size: 10px!important;
  text-align: center;
}


.Cursor {
  cursor: pointer;
}

.form-control-invalid {
    border-color: #FF6060; 
}

.buttonList> div {
  display: block;   
  margin-top: 5px !important; 
}

.buttonList > div.p-button:not(:last-child) {
  border-right: 1px solid #ced4da;
  text-align: left !important;
}

.buttonList > div.p-button { 
  text-align: left !important;
}

.buttonListInvalid > div.p-button  {
  border-color: #f44336 !important;
  text-align: left !important;
}

.react-sign-canvas {
  background-color: #dfdfdf;
  border: 1px solid #525f7f;
  width: 100%;
  height: 100%;
}

.questionary-title {
  background-color: #f6f9fc;  
  min-height: 45px;
  color: #8898aa; 
  border-top: 1px solid #e9ecef; 
  border-bottom: 1px solid #e9ecef; 
  font-weight: bold;
}
 

.scroll-y {
  max-height: 200px;
  overflow-y: auto; 
  overflow-x: hidden;
}
 
.cellList> div {
  display: float;   
  margin-top: 5px !important; 
  margin-right: 5px !important; 
  width: 45px; height: 25px; 
}

.cellList > div.p-button:not(:last-child) {
  border-right: 1px solid #ced4da; 
}

.cellListInvalid > div.p-button  {
  border-color: #f44336 !important; 
} 

.scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #4285F4; }
  
  .scrollbar-primary {
  scrollbar-color: #4285F4 #F5F5F5;
  }
  
  .scrollbar-danger::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-danger::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-danger::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #ff3547; }
  
  .scrollbar-danger {
  scrollbar-color: #ff3547 #F5F5F5;
  }
  
  .scrollbar-warning::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-warning::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-warning::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #FF8800; }
  
  .scrollbar-warning {
  scrollbar-color: #FF8800 #F5F5F5;
  }
  
  .scrollbar-success::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-success::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-success::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #8FCD78; }
  
  .scrollbar-success {
  scrollbar-color: #8FCD78 #F5F5F5;
  }
  
  .scrollbar-info::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-info::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-info::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #33b5e5; }
  
  .scrollbar-info {
  scrollbar-color: #33b5e5 #F5F5F5;
  }
  
  .scrollbar-default::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-default::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-default::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #2BBBAD; }
  
  .scrollbar-default {
  scrollbar-color: #2BBBAD #F5F5F5;
  }
  
  .scrollbar-secondary::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #F5F5F5;
  border-radius: 10px; }
  
  .scrollbar-secondary::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }
  
  .scrollbar-secondary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);  
  background-color: #aa66cc; }
  
  .scrollbar-secondary {
  scrollbar-color: #aa66cc #F5F5F5;
  }


  
/*CSS*/
.loader-parent {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  background: #fff;
  opacity: 0.5;
  font-size: 2.7em !important;
}

.loader-content {
  width: 60px;
  height: 60px;
  font-size: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: -30px;
  z-index: 9999999;
}

.mswSpinner {
  background-image:url('../img/rwp.png');
  width: 65px;
  height: 60px;
}


html {
  font-family: sans-serif; 
  font-size: 13px;
}

body { 
  line-height:18px;
  color: #333333;
}

.form-group {
  margin-bottom: 7px;
}

.p-panel .p-panel-content {
  padding: 4px; 
  padding-top: 15px; 
}


.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

.p-toolbar {
  background: inherit;
  border: 0px;
  padding: 0px; 
}

.p-sidebar-content {
  padding-top: 5px;
}


.bg-card-header-blue {
  background: #296CCC !important;  
}

.bg-card-header-grid {
  background: white !important;  
  color: #435470 !important;  
}

.bg-card-footer-gray{
  background-color: #EDEDED !important;
  color: #777777 !important;  
}


/* Theme Tabs css */
.p-tabview-nav {
  flex-direction: column !important;
}

@media (min-width: 576px) {
  .p-tabview-nav {
    flex-direction: row !important;
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {   
  color: #6e6c6c;
  padding: 1rem; 
  font-weight: 600;  
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;   
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0; 
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #ffffff;
  border-color: #2791FF;
  color: #6e6c6c; 
  border-width: 0 0 2px 0;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #2791FF; 
  border-color: none !important; 
  color: white;  
}
 
.p-tabview .p-tabview-nav li.p-highlight a.p-tabview-nav-link::after { 
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #2b90d9;
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  
}

.p-tabview-nav-link span {
  text-align: center !important;
  width: 100%;
  font-size: 1.2rem !important;
}
 
/* With arrow tabs */
 
.card-footer {
  padding: 5px 10px;
}

.card-chart-value {
  font-size: 25px;
  font-weight: bold;
  color: #636465;
}

.light-blue {
  background: #00b7c3 !important;
  color: #ffffff !important;
}
.light-red {
  background: #FF6060 !important;
  color: #ffffff !important;
}

.light-green {
  background: #20d891 !important;
  color: #ffffff !important;
}


.hard-blue {
  background: #435470 !important;
  color: #ffffff !important;
}

.hard-gray {
  background: #636465 !important;
  color: #ffffff !important;
}

.sky-blue {
  background: #457dcc !important;
  color: #ffffff !important;
}

.azul-medio {
  background: #215ABF !important;
  color: #ffffff !important;
}

.azul-fuerte {
  background: #2E4480 !important;
  color: #ffffff !important;
}

.highcharts-credits {
  display: none !important;
}

.header-dashboard {
  background: #ffffff !important;  
  border-left: 3px solid #296ccc;
  border-right: 3px solid #296ccc; 
  padding-top: 10px; 
  padding-bottom: 10px; 
}


.header-dashboard-title {
  font-size: 30px;
  font-weight: bold; 
  color: #636465;
  padding-bottom: 10px;
}

.header-dashboard-sub-title {
  font-size: 14px; 
  color: #636465;
  text-decoration: underline;
  background-color: #e4e4e4;
  padding-top: 5px; 
  padding-bottom: 5px;
}
 
.header-dashboard-sub-title-2 {
  font-size: 30px; 
  color: #636465;
  background-color: #c8c8c8; 
  padding-top: 5px; 
  padding-bottom: 5px;
  text-align: right;
  padding-right: 5px;
  font-weight: bold;
}


div[data-highcharts-chart] { 
  overflow: visible !important; 
}


.green{
  color:#4caf50;
}

.red{
  color:#FF6060;
}
.primary {
  background: #007bff !important;
  color:#ffffff !important;
}
.primary-color { 
  color:#007bff !important;
}
.default {
  background: #D9DDE8 !important;
  color:#777 !important;
}
.default-color { 
  color:#777 !important;
}
.default-light {
  background: #FFFFFF !important;
  color:#777 !important;
}
.default-light-color { 
  color:#FFFFFF !important;
}

.default-form { 
  background:#f8f9fa !important;
  color:#777 !important;
}
.default-form-color { 
  color:#DEE2E6 !important;
}

.secondary {
  background: #6c757d !important;
  color:#ffffff !important;
}
.secondary-color { 
  color:#6c757d !important;
}
.success{
  background: #28a745 !important;
  color:#ffffff !important;
}
.success-color{ 
  color:#28a745 !important;
}
.success2{
  background: #00B679 !important;
  color:#ffffff !important;
}
.success2-color{ 
  color:#00B679 !important;
}
.success3{
  background: #E8EEE9 !important;
  color:#2D322B !important;
}
.success3-color{ 
  color:#E8EEE9 !important;
}
.danger{
  background: #FF6060 !important;
  color:#ffffff !important;
}
.danger-color{ 
  color:#FF6060 !important;
}
.warning{
  background: #ffc107 !important;
  color:#282529 !important;
}
.warning-color{ 
  color:#ffc107 !important;
}
.warning-light{
  background: #FEF8E5 !important;
  color:#777 !important;
}
.warning-light-color{ 
  color:#ffc107 !important;
}
.orange{
  background: #FE8E6B !important;
  color:#fff !important;
}
.orange-color{ 
  color:#FE8E6B !important;
}
.orange2{
  background: #FF761A !important;
  color:#fff !important;
}
.orange2-color{ 
  color:#FF761A !important;
}
.info{
  background: #17a2b8 !important;
  color:#ffffff !important;
}
.info-color{ 
  color:#17a2b8 !important;
}
.info2{
  background: #1C398D !important;
  color:#ffffff !important;
}
.info2-color{ 
  color:#1C398D !important;
} 

.datatable-info .p-datatable-header{
  background: #34B5FF !important;
  color: #ffffff !important;
  padding: 4px 14px 4px 19px !important;
}

.datatable-info.p-datatable .p-datatable-thead > tr > th {
  background: #34B5FF  !important;
  color: #ffffff !important;
  padding: 4px 14px 4px 19px !important;
}

.datatable-info.p-datatable .p-datatable-scrollable-header{
  background: #34B5FF  !important;
  border-right: 2px solid #a6d5fa;
  border-radius: 25px 25px 0px 0px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.datatable-info.p-datatable .p-datatable-scrollable-body{
  overflow: auto;
  position: relative;
  border-right: 1px solid #34B5FF;
  border-bottom: 1px solid #34B5FF;
}

.divRouned{
  border-radius: 0px 0px 10px 10px !important;
  border-left: 1px solid #34B5FF;
  border-bottom: 1px solid #34B5FF;
  border-right: 1px solid #34B5FF;
}
.divRounedright{
  border-radius: 0px 0px 15px 0px !important;
  border-right: 1px solid #34B5FF;
}

.datatable-info.p-datatable .p-datatable-tbody > tr > td {
  border-right: 1px solid #E2EEF5;
  background: #f8f9fa;
}

.datatable-info.p-datatable .p-datatable-tbody > tr{
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #a6d5fa;
  border-left: 2px solid #a6d5fa;
  border-top: 2px solid #a6d5fa;
}


.light{
  background: #e0e0e0 !important;
  color:#282529 !important;
}
.light-color{
  color:#e0e0e0 !important;
}
.black{
  background: #000 !important;
  color:#fff !important;
}
.black-color{
  color:#000 !important;
}
.dark{
  background: #6A6A74 !important;
  color:#343434 !important;
}
.dark-color{
  color:#343434 !important;
}
.dark:hover{
  background: #FFD202 !important;
  color:#343434 !important;
}
.dark-color:hover{
  color:#343434 !important;
}

.MuiFormControl-root {
  min-width: 100% !important;
} 

.padding2{
  padding: 2px;
}

.padding5{
  padding: 5px;
}

.marginRight2{
  margin-right: 2px;
}

.padding10{
  padding: 10px;
}

.padding50{
  padding: 50px;
}

.paddingLeft5{
  padding-left: 5px;
}
.paddingLeft10{
  padding-left: 10px;
}

.paddingRight10{
  padding-right: 10px;
}

.paddingRight15{
  padding-right: 15px;
}

.paddignTop10{
  padding-top: 10px;
}

.paddignTop5{
  padding-top: 5px;
}

.paddignBottom5{
  padding-bottom: 5px;
}

.paddignBottom10{
  padding-bottom: 10px;
}

.dialogHeader{
  text-align: center;
}

.dialogHeader h1{
   font-size: 22px;
   color: #777777 !important;
}


.bg-title {
  background: #E2E4F3 !important;
  padding: 10px;
  padding-left: 20px;
  font-size: 18px;
  font-family: inherit;
  font-weight: bold;
  color: #25262B;

}

.main-content {
  min-height: 96vh;
}

.bold{
   font-weight: bold;
}

.left{
  text-align: left;
}

.cssAutocomplete .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item{
 padding: 0 !important;
}

.RWP-p-inputgroup{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.RWP-p-inputgroup-addon{
  background: #F5F5F5;
  color: #6c757d;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  padding: 0.5rem 0.5rem;
  min-width: 2.357rem;
}

.RWP-p-inputgroup-label{
  background: #FFFFFF;
  color: #6c757d;
  padding: 0.5rem 0.5rem;
  min-width: 5.0rem;
} 

.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init{
  width: 2rem;
  height: 2rem;
  color: #FF9F54;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save{
  width: 2rem;
  height: 2rem;
  color: #549FFF;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel{
  width: 2rem;
  height: 2rem;
  color: #F14135;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  -webkit-transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active{
  background: #FFD202;
  color:#000!important;
  margin-right: .5rem;
  margin-left: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.375rem;
}

.navbar-vertical .navbar-nav .nav-link[data-toggle="collapse"][aria-expanded="true"]:after{
  color: #FFD202;
  transform: rotate(90deg);
}

.nav-sm flex-column nav{
  color: #FF6060!important;
}

.p-button.p-button-orange, .p-buttonset.p-button-orange > .p-button, .p-splitbutton.p-button-orange > .p-button{
  color: #FFF;
  background: #FF6060;
  border: 1px solid #FF6060;
}


.hidebutton .p-splitbutton-defaultbutton{
  display: none;
}

.hidebutton .p-splitbutton-menubutton{
  background: orange !important;
  border: none !important;
}

.hidebutton .p-button-icon-only{
  border-radius: 50%;
  height: 2.357rem;
}

.p-button.p-button-black:enabled, .p-buttonset.p-button-black > .p-button:enabled, .p-splitbutton.p-button-black
{
  background: #000!important;
  color: #fff!important;
  border-color: #000!important;
}

.align-right{
  text-align: right;
}

.bg-white{
  background-color: #1C1920 !important;
}

.nav-link{
  color:#fff !important;
}
.header-label{
  color: #FFD202;
}
.headerheader-label-value{
  color: #FFF;
}
.p-button.p-button-text{
  background-color: transparent;
  color: #8092BA;
  border-color: transparent;
} 

.p-dialog-header{
  color: #495057;
  text-align: center !important;
}

div.p-dialog-header{
  text-align: center !important;
}

.p-dialog .p-dialog-header{
  color: #495057;
  text-align: center !important;
}

.dialogradius{
  color:#fff!important;
}

.dialogradius .p-dialog-header{
  text-align: center;
  border-radius: 25px 25px 0px 0px;
}


.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover{
  background: #8FCD78;
  color: #ffffff;
  border-color: #8FCD78;
}

.p-button.p-button-success{
  background: #8FCD78;
  color: #ffffff;
  border-color: #8FCD78;
}

.panel-radius .p-panel-content{
  border-radius:0px 0px 25px 25px;
}

.p-calendar-danger .p-button{
  background: #FF6060!important;
  border-color: #FF6060;
  border-radius: 0px 10px 10px 0px;
}
.p-calendar-success .p-button{
  background: #8FCD78!important;
  border-color: #8FCD78;
  border-radius: 0px 10px 10px 0px;
}

.p-steps .p-steps-item.p-highlight .p-steps-number{
  background: #8FCD78!important;
  color: #fff;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number{
  color: #ffffff;
  border: 1px solid #e9ecef;
  background: #A5ABB9;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}

.p-selectbutton .p-button{
  border-radius:15px;
}

.p-selectbutton .p-button.p-highlight{
  background: linear-gradient(87deg, #3242E9 0, #3F8AF2 100%) !important;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item{
  margin: 0;
  /* padding: 0.5rem 1rem; */
  padding:0;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.row-Autocomplete:hover{
  color: #777!important;
  background: #E2F1FC!important;
}

.p-autocomplete-panel .p-autocomplete-items{
  padding: 0.5rem 0.5rem;
}


.p-inputtext{
  border-radius: 10px;
}


.p-steps .p-steps-item:before{
  content: " ";
  border-top: 2px dotted #dee2e6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-steps .p-steps-item.itemInicio:before{
  content: " ";
  border-top: 2px dotted #dee2e6;
  width: 100%;
  top: 50%;
  left: 50%;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-steps .p-steps-item.itemFinal:before{
  content: " ";
  border-top: 2px dotted #dee2e6;
  width: 50%;
  top: 50%;
  left: 0%;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-datatable-header {
  background: #137AA3 !important;
  color: #ffffff !important;
  padding: 4px 14px 4px 19px !important;
}

.p-datatable .p-datatable-thead > tr > th {
  background: #73BDE4  !important;
  color: #ffffff !important;
  padding: 4px 14px 4px 19px !important;
}

.p-datatable .p-datatable-scrollable-header{
  background: #435470  !important;
  color: #ffffff !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 4px 14px 4px 19px
}
.p-datatable .p-datatable-header{
  background: #f8f9fa;
  color: #495057;
  border-width: 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-radius: 25px 25px 0px 0px;
}

.sidenav-toggler-line{
  height: 2px;
  background-color: #ffc107;
  display: block;
  position: relative;
}

.collapsed-header {
  border-bottom-left-radius: 22px !important;
  border-bottom-right-radius: 22px !important;
}
.labelconfig{
  color: #67697B;
}
.labeldescconfig{
  color: #67697B;
  font-size: 10px;
}
.p-panel-title{
  color:#000;
}
.p-dialog-header{
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-top-left-radius: 22px !important;
  border-top-right-radius: 22px !important;
}
.p-dialog-maximized .p-dialog-header{
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.p-dialog-footer{
  flex-shrink:0;
  border-bottom-left-radius: 22px !important;
  border-bottom-right-radius: 22px !important;
  text-align: center;
}
.p-inputgroup-addon{
  background: #e9ecef;
  color: #6c757d;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  padding: 0.5rem 0.5rem;
  font-style: normal!important;
  font-size: 10px!important;
  min-width: 2.357rem;
} 

.p-inputgroup-addon:first-child{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.p-inputgroup-addon:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.p-inputgroup .p-inputtext, .p-fluid .p-inputgroup .p-inputtext, .p-inputgroup .p-inputwrapper, .p-fluid .p-inputgroup .p-input{
  flex: 1 1 auto;
  width: 1%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}


.p-dropdown{
  display: inline-flex;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 10px;
}

.p-datatable .p-datatable-thead > tr > th{
  text-align: left;
  padding: 1rem 1rem;
  border: 0px;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr > td{
  text-align: left;
  border: 0px;
  border-width: 0 0 1px 0;
  /*padding: 1rem 1rem; NA*/
  outline-color: #a6d5fa;
}


.success-gradient{
  background: linear-gradient(87deg, #5E8F32 0, #7EA55A 100%) !important;
}

/* TimelineDemo.css */

.timeline-demo .custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}

.timeline-demo .p-timeline-event-content,
.timeline-demo .p-timeline-event-opposite {
  line-height: 1;
}

@media screen and (max-width: 960px) {
  .timeline-demo .customized-timeline .p-timeline-event:nth-child(even) {
      flex-direction: row !important;
  }
  .timeline-demo .customized-timeline .p-timeline-event:nth-child(even) .p-timeline-event-content {
      text-align: left !important;
  }
  .timeline-demo .customized-timeline .p-timeline-event-opposite {
      flex: 0;
  }
  .timeline-demo .customized-timeline .p-card {
      margin-top: 1rem;
  }
}


.border-bottom-s{
  border-bottom: 0.5px solid #777;
}
.border-bottom{
  border-bottom: 1px dashed #777;
}
.border-bottom-info2{
  border-bottom: 1px solid #123188;
}
.border-bottom-info{
  border-bottom: 1px solid #2152A3;
}
.border-bottom-info-light{
  border-bottom: 1px solid #D7F0FF;
}


.border-all{
  border: 0.5px solid #CDCDCD;
}

.p-grid small{
   font-size: 10px;
}

.label-lg{
  font-size: 14px;
}

.p-datepicker:not(.p-datepicker-inline){
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 9999!important;
}

.p-datepicker .p-datepicker-header{
  padding: 0.5rem;
  color: #fff;
  background: #FF6060!important;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid #dee2e6;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next{
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  color: #fff;
}


.backcard{
  background-image:url('../img/rwp.png');
}

.customPanel .p-panel-header{
 background: transparent !important;
 border: 0;
 padding: 0.5rem;
 text-align: center;
}
.customPanel .p-panel-content{
 background: transparent !important;
 border: 0;
}

.p-avatar-lg .exlarge{
  width: 6rem !important;
  height: 6rem !important;
  font-size: 5rem !important;
}


/*other*/ 

.gradientblue{
  background-image:  linear-gradient(176deg, #3D9DEA, #4A4EEE)!important;
  color:#fff !important;
}

.gradient-container { 
	border-radius: 5px; 
	color: #B3B8CD;
	padding-top: 30px;
	position: relative;
	width: 100%;
	max-width: 100%;
	text-align: center;
}

.gradient-container .pro {
	color: #231E39;
	background-color: #FEBB0B;
	border-radius: 3px;
	font-size: 14px;
	font-weight: bold;
	padding: 3px 7px;
	position: absolute;
	top: 30px;
	left: 30px;
}

.gradient-container h1 {
 color:#fff;
}

.p-grid{
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: 0;
}

.avatar-text{
  font-size: 9px!important;
}
.p-avatar.p-avatar-lg.avatar-exLarge{
  width: 4rem !important;
  height: 4rem !important;
  font-size: 2rem !important;
}

.p-avatar.p-avatar-lg.avatar-exLarge .p-avatar-icon{
  font-size: 2rem !important;
}

.p-datatable-scrollable-body{
  overflow: auto;
  position: relative;
}

.p-datatable .p-datatable-footer{
  background: #fff;
  color: #495057;
  border: none;
  border-width: 0 0 1px 0;
  padding: 0rem 1rem;
  font-weight: 600;
}

.widget-chart .widget-numbers{
  font-weight: 700;
  font-size: 2.5rem;
  display: block;
  line-height: 1;
  margin: 1rem auto;
}

.btn-outline-alternate:not(:disabled):not(.disabled).active, 
.btn-outline-alternate:not(:disabled):not(.disabled):active, 
.show>.btn-outline-alternate.dropdown-toggle{
  color: #fff;
  font-size: 10px;
  min-width: 60px;
}

.btn.btn-pill, .btn.btn-pill.btn-wide{
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}


.datatable-custom.p-datatable .p-datatable-thead > tr > th {
  display: none;
}
 
.datatable-custom .p-datatable-scrollable-body{
  overflow-x: hidden!important;
  position: relative!important;
}

.datatable-custom.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover{
  background: transparent;
  color: #495057;
}

.datatable-custom.p-datatable-hoverable-rows .p-selectable-row{
  cursor: auto;
}
.fsize-1{
  font-size: 1.2rem!important;
}
.fsize-4{
  font-size: 2rem!important;
}
.small, small{
  font-size: 80%;
  font-weight: 400;
}

.p-progressbar.warning-color .p-progressbar-value{
  border: 0 none;
  margin: 0;
  background: #ffc107 !important;
}

.p-progressbar.success-color .p-progressbar-value{
  border: 0 none;
  margin: 0;
  background: #8FCD78 !important;
}

.subtitule{
  font-weight: bold;
  color: #123188;
  font-size: 28px;
}

.tblHeader{
    background: #34B5FF !important;
    color: #fff;
    border-top: 2px solid #a6d5fa;
    border-left: 2px solid #a6d5fa;
    border-right: 2px solid #a6d5fa;
    border-radius: 25px 25px 0px 0px !important;
    padding-top: 5px;
}

.tblFooter{
  border-top: 2px solid #a6d5fa;
  color: #fff;
  border-radius: 0px 0px 10px 0px !important;
}

.tblFooter2{
  background: #34B5FF  !important;
  color: #fff;
  border-top: 2px solid #a6d5fa;
  border-left: 2px solid #a6d5fa;
  border-right: 2px solid #a6d5fa;
  border-radius: 0px 0px 10px 0px !important;
}

.tb-table{
    background: #fff  !important;
    color: #777;
    border-right: 1px solid #a6d5fa;
    border-top: 1px solid #a6d5fa;
    border-radius: 25px 25px 0px 0px !important;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
}



.table1 {
  margin-bottom: 2em;
  border-spacing: 0pt;
  border-collapse: separate;
}

.table1 thead {
  background: #34B5FF !important;
  color: #fff;
}


.table1 th,
td {
  padding: 10pt;
  border: 1px solid #34B5FF;
}

.table1 tr:first-child th:first-child,
.table1 tr:first-child td:first-child {
  border-top-left-radius: 15px;
}

.table1 tr:first-child th:last-child,
.table1 tr:first-child td:last-child {
  border-top-right-radius: 15px;
}

.table1 tr:last-child th:first-child,
.table1 tr:last-child td:first-child {
  border-bottom-left-radius: 0px;
}

.table1 tr:last-child th:last-child,
.table1 tr:last-child td:last-child {
  border-bottom-right-radius: 0px;
}


.table1 .tfooter tr:first-child th:first-child,
.table1 .tfooter tr:first-child td:first-child {
  border-top-left-radius: 0px;
}

.table1 .tfooter tr:first-child th:last-child,
.table1 .tfooter tr:first-child td:last-child {
  border-top-right-radius: 0px;
}

.table1 .tfooter .trroundleft,
.table1 .tfooter .trroundleft {
  border-bottom-left-radius: 15px !important;
}

.table1 .tfooter .trroundright,
.table1 .tfooter .trroundright {
  border-bottom-right-radius: 15px !important;
}

.not-border{
  border: none !important;
}
.not-border-left{
  border-left: none !important;
}
.not-border-right{
  border-right: none !important;
}

.p-dialog.headerdialog .p-dialog-header{
  color: #495057;
  text-align: center !important;
  padding: 0.5rem !important;
  
}

hr{
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.img-thumbnail{
  padding: 0.20rem;
  background-color: transparent;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}


.product-card-category {
  border-radius: 5px;
  margin-bottom: 0;
  box-shadow: 0 1px 2px 0 #e5e7eA;
  border-width: 1px;
  border-color: #e5e7eA;
  border-style: solid;
  transition: top ease 1s; 
  width: 280px!important;
  min-height: 180px!important;
}

.product-card-category-image-container {
position: relative;
width: 100%;
}

.product-card-category-image-container-content {   
cursor: pointer;     
position: relative;
width: 100%;
height: 100%;
display: flex;
align-items: center !important;
justify-content: center !important;

}

.product-card-category-image-container-content img {
width: auto;
height: auto;
max-width: 100%;
max-height: 100%;
border-radius: 0px;
margin-left: auto;
margin-right: auto;
}


.product-card-category .card-header{ 
  border:none;
}

.rowtestfixed{
  text-align: center;
  font-size: 1.2rem;
  padding-top: 2px;
  padding-bottom: 15px;
  background: #ffc107;
  color: #282529!important;
  position: fixed;
  bottom: 0px;
  z-index: 1001;
  width: 100%;
}

.no-border {
  border: 0px !important;
}