
.treevew-button {
    cursor: pointer;
  }
  .treeViewWrapper {
    position: relative;
  }
  .treeButtonPanel {
    position: absolute;
    z-index: 90;
    width: 100%;
    top: 35px;  
    box-shadow: 0 0 8px rgb(0 0 0 / 35%);
    border-radius: 1em; 
    --overlayArrowLeft:0px;
  }
  
  .p-tree {
    border: 0px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    padding: 1rem;
    border-radius: 1em;
  }
  
  .treeButtonPanel:after
  {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 8px 16px;
  border-color: #FFFFFF transparent;
  display: block;
  width: 0;
  z-index: 91;
  margin-left: -8px;
  top: -16px;
  left: 50%;
  }
  
  .treeButtonPanel:before
  {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 9px 17px;
  border-color: #dee2e6 transparent;
  display: block;
  width: 0;
  z-index: 91;
  margin-left: -9px;
  top: -19px;
  left: 50%;
  }

  
.p-treeselect-panel{
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 9999;
}

.tree .clear{
  color: #777777;
  margin-top: -28px;
  position: absolute;
  right: 28px;
  font-size: 1.5em;
  cursor: pointer;
  font-family: 'primeicons';
  display: flex;
  align-items: stretch;
  justify-content: end;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  margin-left: auto;
}

.tree .clear:hover{
  color: #505050;
}
